<template>
  <DefaultLoader :loading="loading">
    <form @submit.prevent="Submit">
      <small v-if="is_enabled"
        >Enter the Timebased OTP generated and click disable to deactivate
        2fa</small
      >
      <small v-else
        >Enter the Timebased OTP generated and click enable to activate
        2fa</small
      >
      <InputWrapper>
        <input
          class="form-control"
          id="otp"
          type="number"
          v-model="formData.code"
          placeholder="Enter OTP"
          required
        />
      </InputWrapper>
      <div class="mt-3 text-center">
        <button type="submit" class="btn btn-dark">
          {{ is_enabled ? "Disable 2FA" : "Enable 2FA" }}
        </button>
      </div>
    </form>
  </DefaultLoader>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import InputWrapper from "@/components/InputWrapper.vue";
import { apiPatch } from "@/api";
import { Toast } from "@/utils/notify";
import { useStore } from "vuex";
import DefaultLoader from "@/components/loader/DefaultLoader.vue";

export default defineComponent({
  name: "TwoFAUpdate",
  components: { DefaultLoader, InputWrapper },
  props: {
    is_enabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["updated"],
  setup(props, ctx) {
    const formData = ref({
      code: null,
    });
    const store = useStore();
    const loading = ref(false);
    const Submit = async () => {
      loading.value = true;
      try {
        await apiPatch(`/user-account/${store.getters.id}/2fa`, formData.value);
        Toast.fire({
          icon: "success",
          title: "Updated Successfully",
        }).then();
        ctx.emit("updated");
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to update 2fa: " + e.message,
        }).then();
      }
      loading.value = false;
    };
    return {
      Submit,
      loading,
      formData,
    };
  },
});
</script>

<style scoped></style>
