<template>
  <HourglassLoader :loading="loading">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-6 col-xl-4">
        <template v-if="data">
          <div class="card border-light text-center py-5">
            <div v-if="data?.is_enabled" class="card-body">
              <div class="mb-3">
                <small class="text-success">You have 2FA enabled</small>
              </div>
              <TwoFAUpdate :is_enabled="true" @updated="RefreshData" />
            </div>
            <div v-else class="card-body">
              <div class="card card-body">
                <small class="text-danger" style="font-size: small"
                  >Please write down the secret key below and keep it safe from
                  third party! It is the only way you can access your account if
                  you lost your device</small
                >
                <h6>{{ data.secret }}</h6>
              </div>
              <div>
                <small>
                  Please scan the barcode below using your authenticator App
                  (e.g Google Authenticator)
                </small>
              </div>
              <img :src="data?.url" class="mx-auto my-3" alt="2fa Data" />
              <TwoFAUpdate :is_enabled="false" @updated="RefreshData" />
              <div class="text-info mt-4 fw-bolder">
                <small
                  >Please note that you'll be required to complete login with a
                  one time code from your authenticator app everytime</small
                >
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="alert alert-dark text-white text-center">
            <p>Currently unable to verify 2FA status</p>
            <button class="btn btn-sm btn-light" @click="RefreshData">
              Retry
            </button>
          </div>
        </template>
      </div>
    </div>
  </HourglassLoader>
</template>

<script lang="ts">
import { useStore } from "vuex";
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import { ref, defineComponent, onBeforeMount } from "vue";
import { apiGet } from "@/api";
import { Toast } from "@/utils/notify";
import TwoFAUpdate from "@/components/profile/TwoFAUpdate.vue";

export default defineComponent({
  name: "2FAuthentication",
  components: { TwoFAUpdate, HourglassLoader },
  setup() {
    const store = useStore();
    const loading = ref(false);
    const data = ref(null);

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(`/user-account/${store.getters.id}/2fa`);
        data.value = response.data.data;
        Toast.fire({
          icon: "success",
          title: "Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch 2fa status: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      store,
      data,
      loading,
      RefreshData,
    };
  },
});
</script>

<style scoped></style>
